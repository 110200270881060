<template>
  <div style="height: calc(100vh - 225px); overflow:auto;">
    <v-card
      flat
      outlined
      class="mb-5 mx-5 mt-5"
      v-for="notificacao in notificacoes"
      :key="notificacao.id"
    >
      <div v-if="notificacao.calendario">
        <v-card-title
          class=" grey lighten-5 pt-1 pb-0 text-subtitle-1 font-weight-black"
          >{{ notificacao.calendario.cliente_nome_fantasia }} | Início:
          {{ notificacao.calendario.inicio | dateFormat("dd/MM/yyyy") }}
          - Fim:
          {{ notificacao.calendario.fim | dateFormat("dd/MM/yyyy") }}
          <v-spacer></v-spacer>
          {{ notificacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-card
          tile
          flat
          class="pt-5 pb-5 grey lighten-5 d-flex text-center align-center text-justify-center font-weight-black"
        >
          <v-avatar class="mx-3 " size="50" v-if="notificacao.usuario_avatar">
            <v-img :src="notificacao.usuario_avatar" contain></v-img>
          </v-avatar>
          <v-icon v-else class="mx-3" size="50">mdi-account-circle</v-icon>
          {{ notificacao.usuario_name }}
          <v-btn rounded class="ml-2" x-small dark
            >{{ notificacao.acao }} {{ notificacao.oque }}</v-btn
          >
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="corStatusCalendario(notificacao.acao_valor_antigo)"
            >{{ +notificacao.acao_valor_antigo | statusCalendario }} ->
          </v-btn>
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="corStatusCalendario(notificacao.acao_valor_novo)"
            >{{ +notificacao.acao_valor_novo | statusCalendario }}
          </v-btn>
        </v-card>
      </div>
      <div v-else-if="notificacao.atividade">
        <v-card-title
          class=" grey lighten-5 pt-1 pb-0 text-subtitle-1 font-weight-black"
          >{{ notificacao.atividade.cliente_nome_fantasia }} |
          {{ notificacao.atividade.titulo }}
          <v-spacer></v-spacer>
          {{ notificacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-card
          tile
          flat
          class="pt-5 pb-5 grey lighten-5 d-flex text-center align-center text-justify-center font-weight-black"
        >
          <v-avatar class="mx-3 " size="50" v-if="notificacao.usuario_avatar">
            <v-img :src="notificacao.usuario_avatar" contain></v-img>
          </v-avatar>
          <v-icon v-else class="mx-3" size="50">mdi-account-circle</v-icon>
          {{ notificacao.usuario_name }}
          <v-btn rounded class="ml-2" x-small dark
            >{{ notificacao.acao }} {{ notificacao.oque }}</v-btn
          >
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="
              notificacao.acao_valor_antigo == 0
                ? 'grey'
                : notificacao.acao_valor_antigo == 1
                ? 'blue-grey'
                : notificacao.acao_valor_antigo == 2
                ? 'yellow darken-4'
                : notificacao.acao_valor_antigo == 3
                ? 'green'
                : notificacao.acao_valor_antigo == 4
                ? 'purple'
                : notificacao.acao_valor_antigo == 5
                ? 'green darken-4'
                : notificacao.acao_valor_antigo == 6
                ? 'deep-purple accent-4'
                : notificacao.acao_valor_antigo == -1
                ? 'red'
                : 'light-green'
            "
            >{{ +notificacao.acao_valor_antigo | statusAtividade }} ->
          </v-btn>
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="
              notificacao.acao_valor_novo == 0
                ? 'grey'
                : notificacao.acao_valor_novo == 1
                ? 'blue-grey'
                : notificacao.acao_valor_novo == 2
                ? 'yellow darken-4'
                : notificacao.acao_valor_novo == 3
                ? 'green'
                : notificacao.acao_valor_novo == 4
                ? 'purple'
                : notificacao.acao_valor_novo == 5
                ? 'green darken-4'
                : notificacao.acao_valor_novo == 6
                ? 'deep-purple accent-4'
                : notificacao.acao_valor_novo == -1
                ? 'red'
                : 'light-green'
            "
            >{{ +notificacao.acao_valor_novo | statusAtividade }}
          </v-btn>
        </v-card>
      </div>
    </v-card>
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="no-results" class="my-6">
        Você ainda não tem nenhuma notificacão!
      </div>
      <div slot="spinner" class="my-6">
        <v-icon>mdi-loading mdi-spin</v-icon> Carregando...
      </div>
      <div slot="no-more" class="my-6">
        Todas as suas notificacões foram carregadas.
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import {
  fetchNotificacoesLidasColaborador,
  fetchNotificacoesLidasCliente,
  fetchNotificacoesLidasGestor,
} from "@/api/geral/notificacoes.js";
import InfiniteLoading from "vue-infinite-loading";
export default {
  name: "LidasNotificacoes",
  props: {
    perfil_id: {
      type: [String, Number],
      default: null,
    },
    user_id: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      notificacoes: [],
      page: 1,
      infiniteId: +new Date(),
    };
  },
  components: {
    InfiniteLoading,
  },
  methods: {
    changeType() {
      this.page = 1;
      this.notificacoes = [];
      this.infiniteId += 1;
    },
    corStatusCalendario(item) {
      let result;
      if (item) {
        if (item == -1) {
          result = "#F44336";
        }
        if (item == 1) {
          result = "#263238";
        }
        if (item == 2) {
          result = "#E65100";
        }
        if (item == 3) {
          result = "#33691E";
        }
        if (item == 4) {
          result = "#1B5E20";
        }
      }
      return result;
    },
    infiniteHandler($state) {
      if (this.perfil_id === 1 || this.perfil_id === 2) {
        return fetchNotificacoesLidasGestor(
          `?user_id=${this.user_id}&page=${this.page}`
        ).then(({ data }) => {
          if (data.length) {
            this.page += 1;
            this.notificacoes.push(...data);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      } else if (this.perfil_id === 3) {
        fetchNotificacoesLidasColaborador(
          `?user_id=${this.user_id}&page=${this.page}`
        ).then(({ data }) => {
          if (data.length) {
            this.page += 1;
            this.notificacoes.push(...data);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      } else if (this.perfil_id === 4) {
        fetchNotificacoesLidasCliente(
          `?user_id=${this.user_id}&page=${this.page}`
        ).then(({ data }) => {
          if (data.length) {
            this.page += 1;
            this.notificacoes.push(...data);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      }
    },
  },
};
</script>

<style></style>
